import React from 'react'
import './Market.css'

import Showcase_app from '../../assets/img/Showcase_App.png'
import Playstore from '../../assets/img/PlayStore.png'
import AppStore from '../../assets/img/AppStore.png'

const Market = () => {
  return (
    <>
      <section className="container market_section" >

        <div className='market_titre'>

          <span>
            Ornoir  
          </span>

          <span>
            Market
          </span>

        </div>

        <div className='Bloccentral'>
          
          <div className='bloc'>
            <img src={Showcase_app} alt="Application showcase" style={{/*height:'755px'*/}} />
          </div>

          <div className='bloc'>

            <div className='Heading_market'>

              <span className='Heading_market_1'>
                Principale application <br />
              </span>
              <span className=''>
                de vente  <br />
                en 
                Gros  et Détails <br />
              </span>

              <span className=''>
                en Afrique Centrale 
              </span>
            </div>
            
            <p className='market_p'>
              Découvrez des produits et des fournisseurs pour votre entreprise parmi des millions d'offres dans le monde entier. Commander en toute transparence, de la recherche de produits/fournisseurs à la gestion des commandes, au paiement et à l'exécution.
            </p>
            
            <div className='parentDownloadButton' >

              <div className="DownloadButton" >
                <img src={Playstore} alt=""/>
              </div>
              <div className="DownloadButton">
                <img src={AppStore} alt="" />
              </div>
            </div>

          </div>

          


        </div>

        <div id='PortailWeb'>

            <a href="https://www.market.ornoir.cg">
              Aller vers la Market Place Web
            </a>
           
        </div>

        

      </section>

    </>

  )
}

export default Market